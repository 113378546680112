import React, { Component } from 'react';
import $ from 'jquery';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatePicker from "react-datepicker";
import moment from 'moment';
import MultiSelect from '../ReusableComponents/customMultiSelect';
import { validationParser } from '../ReusableComponents/validationParser2';
import { formObjDataMapper } from '../ReusableComponents/formObjDataMapper';
import { selectOptionsGenerator, multiSelectOptionGenerator, createMultiselectDataToSend } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';

var validationObj = {};

var context;

const axios = require('axios').default;
class Cssr extends Component {
    constructor() {
        super();

        context = this;

        this.state = {

            cssrData: this.getModal(),
            mandatoryInfo: this.getModal(),
            labelText: this.getModal(),
            tooltips: this.getModal(),
            tooltipsVisibility: this.getModal(),
            fieldName: this.getModal(),
            cssrHistoryData: [],

            //Select options
            actualSuicideAttemptSelectOptions: [],
            interruptedAttemptSelectOption: [],
            abortSelfIntruptAttempt: [],
            //MultiSelect options
            suicidalIdeationMultiOptions: [],
            activatingEventsMultiOptions: [],
            treatmentHistoryMultiOptions: [],
            treatmentRiskFactorMultiOptions: [],
            clinicalStatusMultiOptions: [],
            protectiveFactorMultiOptions: [],
            protectiveOthrRiskFactorMultiOptions: [],
            preparatory_acts_options: [],
            self_injurious_behavior_options: [],

            activating_events_options: [],
            other_activating_events_options: [],

        };

    }

    getModal() {
        var cssrDataModal = {
            actual_suicide_attempt: '',
            interrupted_attempt: '',
            abort_self_intrupt_attempt: '',
            preparatory_acts: '',
            self_injurious_behavior: '',
            suicidal_ideation: '',
            activating_events: '',
            other_activating_events: '',
            treatment_history: '',
            treatment_risk_factor: '',
            clinical_status: '',
            protective_factor: '',
            protective_othr_risk_factor: '',
            description: '',
        };

        return cssrDataModal;
    }

    componentWillMount() {
        //for validation

        axios.get(global.restApiURL + 'webservice/rest/class/id/14?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                var response = res.data;

                if (response.success) {
                    validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
                    //   console.log("validation" , validationObj) ;

                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name
                    });

                    this.createSelectOptions();

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });

    }

    componentDidMount() {
        this.props.onRef(this)
        var prevExist = false;
        sessionStorage.setItem('ObectID', '');

        if (sessionStorage.getItem('clientId') !== '') {
            axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&objectClass=CSSR&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
                .then(res => {

                    // console.log("res : " , res) ;
                    const response = res.data; // get the data array instead of object


                    if (response.success) {
                        console.log("object fetcher : ", response);

                        if (response.data.length !== 0) {
                            prevExist = true;
                            console.log("prevExist : ", prevExist);
                            this.setState({
                                cssrHistoryData: response.data
                            })
                        } else {
                            prevExist = false;
                        }
                    }
                    else if ("error_code" in response) {
                        alert(response.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(response.msg);
                    }

                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })

            if (this.props.obj_id && this.props.obj_id > 0) {
                axios.get(global.restApiURL + 'webservice/rest/object/id/' + this.props.obj_id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
                    .then(res => {

                        sessionStorage.setItem('ObectID', this.props.obj_id);
                        sessionStorage.setItem('Obectkey', res.data.data.key);
                        const response = res.data.data.elements;
                        console.log("Obj data : ", response);
                        //  console.log("mapper : " , formObjDataMapper(responce , this.getModal())) ;

                        this.setState({
                            cssrData: formObjDataMapper(response, this.getModal())
                        })

                        this.createSelectOptions();

                        // this.datamapper(responce)    


                    })
                    .catch(err => { // log request error and prevent access to undefined state
                        this.setState({ loading: false, error: true });
                        console.error(err);
                    })
            }

        }
    }
    //Post data to save

    save = (event) => {
        /*let saveType =  event.target.value ;
        console.log( "save : " , saveType );*/
        let saveType = 'save';

        var jsonData = '';
        if (sessionStorage.getItem('ObectID') && sessionStorage.getItem('Obectkey')) {
            jsonData = { 'className': 'CSSR', 'key': sessionStorage.getItem('Obectkey'), 'id': sessionStorage.getItem('ObectID'), 'published': true };
        } else {
            jsonData = { 'className': 'CSSR', 'key': sessionStorage.getItem('clientId') + '-' + this.state.cssrHistoryData.length, 'published': true };
        }

        jsonData.elements = [];
        var formData = $("#cssrform").serializeArray();

        console.log("formdata : ", formData);

        $.each(formData, function () {
            if (this.name !== "suicidal_ideation" && this.name !== "activating_events" && this.name !== "treatment_history" /*&&
            this.name !== "treatment_risk_factor"*/ && this.name !== "clinical_status" && this.name !== "protective_factor" /*&& this.name !== "protective_othr_risk_factor" */)
                jsonData.elements.push({ name: this.name, value: this.value });
            // else
            //   {
            //     jsonData.elements.push({name:this.name,value: context.state.lastNightStayData[this.name] });
            //   }
        });
        jsonData.elements.push({ name: 'client_id', value: sessionStorage.getItem('clientId') });
        jsonData.elements.push({ name: 'interviewer', value: sessionStorage.getItem('loggedinUserID') });
        jsonData.elements.push({ name: 'updated_time', value: moment().format('MM/DD/YYYY HH:mm:ss') });

        jsonData.elements.push({ name: context.state.fieldName.suicidal_ideation, value: createMultiselectDataToSend(context.state.cssrData.suicidal_ideation) });
        jsonData.elements.push({ name: context.state.fieldName.activating_events, value: createMultiselectDataToSend(context.state.cssrData.activating_events) });
        jsonData.elements.push({ name: context.state.fieldName.treatment_history, value: createMultiselectDataToSend(context.state.cssrData.treatment_history) });
        //jsonData.elements.push({name: context.state.fieldName.treatment_risk_factor , value: createMultiselectDataToSend(context.state.cssrData.treatment_risk_factor)}) ;
        jsonData.elements.push({ name: context.state.fieldName.clinical_status, value: createMultiselectDataToSend(context.state.cssrData.clinical_status) });
        jsonData.elements.push({ name: context.state.fieldName.protective_factor, value: createMultiselectDataToSend(context.state.cssrData.protective_factor) });
        //jsonData.elements.push({name: context.state.fieldName.protective_othr_risk_factor , value: createMultiselectDataToSend(context.state.cssrData.protective_othr_risk_factor)}) ;

        var json = JSON.stringify(jsonData);

        console.log("Data to send : ", json);

        axios.post(global.save_url, json)
            .then(function (res) {
                var response = res.data;
                console.log("save response", response.success);
                if (response.success) {
                    sessionStorage.setItem('success_msg', "CSSR saved successfully.");
                    if ($('#clicked').val() === 'proceed') {
                        window.location.href = global.domain + global.traversArray['cssr'];
                    }
                    else {
                        window.location.href = "";
                    }
                }
                else {
                    sessionStorage.setItem('success_msg', "Something went wrong!");

                }
                sessionStorage.removeItem('Obectkey');
                sessionStorage.removeItem('ObectID');
                console.log("Msg : ", sessionStorage.getItem('success_msg'));
            })
            .catch(function (err) {
                // alert(err.message);
                console.log(err.message);
            });

    }

    createSelectOptions() {
        if (validationObj !== "") {
            this.setState({
                actualSuicideAttemptSelectOptions: selectOptionsGenerator(validationObj.options.actual_suicide_attempt, this.state.cssrData.actual_suicide_attempt),
                interruptedAttemptSelectOption: selectOptionsGenerator(validationObj.options.interrupted_attempt, this.state.cssrData.interrupted_attempt),
                abortSelfIntruptAttempt: selectOptionsGenerator(validationObj.options.abort_self_intrupt_attempt, this.state.cssrData.abort_self_intrupt_attempt),

                suicidalIdeationMultiOptions: multiSelectOptionGenerator(validationObj.options.suicidal_ideation),
                activatingEventsMultiOptions: multiSelectOptionGenerator(validationObj.options.activating_events),
                treatmentHistoryMultiOptions: multiSelectOptionGenerator(validationObj.options.treatment_history),
                //treatmentRiskFactorMultiOptions : multiSelectOptionGenerator(validationObj.options.treatment_risk_factor),
                clinicalStatusMultiOptions: multiSelectOptionGenerator(validationObj.options.clinical_status),
                protectiveFactorMultiOptions: multiSelectOptionGenerator(validationObj.options.protective_factor),
                //protectiveOthrRiskFactorMultiOptions : multiSelectOptionGenerator(validationObj.options.protective_othr_risk_factor),
                preparatory_acts_options: selectOptionsGenerator(validationObj.options.preparatory_acts, this.state.cssrData.preparatory_acts),
                self_injurious_behavior_options: selectOptionsGenerator(validationObj.options.self_injurious_behavior, this.state.cssrData.self_injurious_behavior),

            });

            // console.log("validation obj : " , this.state ) ;
        }
    }

    handleMultiSelectChanges = (selectedOption, event) => {

        var updatedState = this.state.cssrData;

        updatedState[event.name] = selectedOption;

        this.setState(prevState => ({
            ...prevState,
            cssrData: updatedState
        }));

        // console.log( event.name ," : ", this.state.lastNightStayData);
    };

    //Handles save and save proceed buttons
    handleId = (e) => {

        console.log("handle id")

        $('#clicked').val(e.target.value);
        $('#clicked').val(e.currentTarget.value);
    }

    render() {
        sessionStorage.setItem('formname', 'CSSR');

        $(document).ready(function () {

            $('#cssrform').validate({
                rules: {

                },
                errorElement: 'span',
                errorPlacement: function (error, element) {
                    error.addClass('invalid-feedback');
                    element.closest('.input-group').append(error);
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                }
            });
        });


        return (
            <form action="javascript:void(0);" method="post" id="cssrform">

                <div className="">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <label><b>Suicidal and Self-injurious Behaviour</b></label>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.actual_suicide_attempt}</label> <Tooltip text={this.state.tooltips.actual_suicide_attempt} visibiity={this.state.tooltipsVisibility.actual_suicide_attempt} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <select id="actual_suicide_attempt" name="actual_suicide_attempt" className="form-control select" aria-hidden="true">
                                    {this.state.actualSuicideAttemptSelectOptions}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.interrupted_attempt}</label> <Tooltip text={this.state.tooltips.interrupted_attempt} visibiity={this.state.tooltipsVisibility.interrupted_attempt} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <select id="interrupted_attempt" name="interrupted_attempt" className="form-control select" aria-hidden="true">
                                    {this.state.interruptedAttemptSelectOption}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.abort_self_intrupt_attempt}</label> <Tooltip text={this.state.tooltips.abort_self_intrupt_attempt} visibiity={this.state.tooltipsVisibility.abort_self_intrupt_attempt} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <select id="abort_self_intrupt_attempt" name="abort_self_intrupt_attempt" className="form-control select" aria-hidden="true">
                                    {this.state.abortSelfIntruptAttempt}
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.preparatory_acts}</label> <Tooltip text={this.state.tooltips.preparatory_acts} visibiity={this.state.tooltipsVisibility.preparatory_acts} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <select id="preparatory_acts" name="preparatory_acts" className="form-control select" aria-hidden="true">
                                    {this.state.preparatory_acts_options}
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.self_injurious_behavior}</label> <Tooltip text={this.state.tooltips.self_injurious_behavior} visibiity={this.state.tooltipsVisibility.self_injurious_behavior} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <select id="self_injurious_behavior" name="self_injurious_behavior" className="form-control select" aria-hidden="true">
                                    {this.state.self_injurious_behavior_options}
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.clinical_status}</label> <Tooltip text={this.state.tooltips.clinical_status} visibiity={this.state.tooltipsVisibility.clinical_status} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <MultiSelect
                                    name={this.state.fieldName.clinical_status}
                                    value={this.state.cssrData.clinical_status}
                                    onChange={this.handleMultiSelectChanges}
                                    options={this.state.clinicalStatusMultiOptions}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.suicidal_ideation}</label> <Tooltip text={this.state.tooltips.suicidal_ideation} visibiity={this.state.tooltipsVisibility.suicidal_ideation} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <MultiSelect
                                    name={this.state.fieldName.suicidal_ideation}
                                    value={this.state.cssrData.suicidal_ideation}
                                    onChange={this.handleMultiSelectChanges}
                                    options={this.state.suicidalIdeationMultiOptions}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.activating_events}</label> <Tooltip text={this.state.tooltips.activating_events} visibiity={this.state.tooltipsVisibility.activating_events} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <MultiSelect
                                    name={this.state.fieldName.activating_events}
                                    value={this.state.cssrData.activating_events}
                                    onChange={this.handleMultiSelectChanges}
                                    options={this.state.activatingEventsMultiOptions}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>If other, describe events</label> 
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <input className="form-control form-group" type="text" id="other_activating_events" name="other_activating_events" defaultValue={this.state.cssrData.other_activating_events} placeholder="Describe events" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.treatment_history}</label> <Tooltip text={this.state.tooltips.treatment_history} visibiity={this.state.tooltipsVisibility.treatment_history} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <MultiSelect
                                    name={this.state.fieldName.treatment_history}
                                    value={this.state.cssrData.treatment_history}
                                    onChange={this.handleMultiSelectChanges}
                                    options={this.state.treatmentHistoryMultiOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.treatment_risk_factor}</label> <Tooltip text={this.state.tooltips.treatment_risk_factor} visibiity={this.state.tooltipsVisibility.treatment_risk_factor} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                {/*<MultiSelect
                                                            name = {this.state.fieldName.treatment_risk_factor}
                                                            value = {this.state.cssrData.treatment_risk_factor}
                                                            onChange = {this.handleMultiSelectChanges}
                                                            options = {this.state.treatmentRiskFactorMultiOptions}
                                                        />*/}
                                <input type="text" placeholder="Risk Factor" className="form-control" name={context.state.fieldName.treatment_risk_factor} id={context.state.fieldName.treatment_risk_factor} defaultValue={this.state.cssrData.treatment_risk_factor} required={this.state.mandatoryInfo.treatment_risk_factor} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.protective_factor}</label> <Tooltip text={this.state.tooltips.protective_factor} visibiity={this.state.tooltipsVisibility.protective_factor} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                <MultiSelect
                                    name={this.state.fieldName.protective_factor}
                                    value={this.state.cssrData.protective_factor}
                                    onChange={this.handleMultiSelectChanges}
                                    options={this.state.protectiveFactorMultiOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <label>{this.state.labelText.protective_othr_risk_factor}</label> <Tooltip text={this.state.tooltips.protective_othr_risk_factor} visibiity={this.state.tooltipsVisibility.protective_othr_risk_factor} />
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="form-group" >
                                {/*<MultiSelect
                                                            name = {this.state.fieldName.protective_othr_risk_factor}
                                                            value = {this.state.cssrData.protective_othr_risk_factor}
                                                            onChange = {this.handleMultiSelectChanges}
                                                            options = {this.state.protectiveOthrRiskFactorMultiOptions}
                                                        />*/}
                                <input type="text" placeholder="Protective Factor" className="form-control" name={context.state.fieldName.protective_othr_risk_factor} id={context.state.fieldName.protective_othr_risk_factor} defaultValue={this.state.cssrData.protective_othr_risk_factor} required={this.state.mandatoryInfo.protective_othr_risk_factor} />
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-12">
                            <label>{this.state.labelText.description} </label> <Tooltip text={this.state.tooltips.description} visibiity={this.state.tooltipsVisibility.description} />
                            <textarea id={this.state.fieldName.description} name={this.state.fieldName.description} className="form-control cssrnote" rows="3" defaultValue={this.state.cssrData.description} placeholder={this.state.labelText.description}></textarea>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-12 col-md-12" style={{ marginTop: "30px", border: "1px dashed #cec5c5", padding: "20px 20px 0px 20px", backgroundColor: "#f2f2f2" }}>
                        <p className=" text-gray"><strong>Source:</strong> Posner, Brent, Lucas, Gould, Stanley, Brown, Fisher, Zelazny, Burke, Oquendo, &amp; Mann
                            © 2008 The Research Foundation for Mental Hygiene, Inc. </p>

                    </div>
                </div>

            </form>

        );
    }
}

export default Cssr