import React, { Component, Fragment } from 'react';
import $, { data } from 'jquery';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { customURLGen, can_add } from '../ReusableComponents/urlGenerator';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import '../global.js';
import { Typeahead } from 'react-bootstrap-typeahead';
import orderBy from 'lodash/orderBy';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DateTime from 'react-datetime';
import Modal from './Modals/Modal/index';
import Select from 'react-select';
import Loader from './Loader';
import { encryptStorage } from './LocalStorageInterceptor';

import { date_validate } from '../ReusableComponents/globalFunctions';
import color from '@material-ui/core/colors/amber';

const axios = require('axios').default;
var now = new Date();

const customSort = (rows, field, direction) => {
  const handleField = row => {
    if (row[field]) {
      console.log('rowfield', row)
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    whiteSpace: "inherit"
  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),

};

class Clients extends Component {

  constructor() {
    super();
    this.setDate = this.setDate.bind(this);
    this.getsearchresult = this.getsearchresult.bind(this);
    this.clearclientid = this.clearclientid.bind(this);

    this.state = {
      search : false,
      startDateintakedate: '',
      data: [],
      pageInfo: {},
      searchValue: null,
      organisation: sessionStorage.getItem('selected_organization'),
      loading: true,
      error: false,
      pending: true,
      showOrganizationModal: false,
      organizationsList: [],
      org_select_error: '',
      listNext: 1,
      top10List: [],
      dateRange: '',
      // Code by ashraf Start
      sortClientList: "",
      // Code by ashraf End
      locale: {
        'format': 'MM/DD/YYYY'
      },
      columns: [
        {
          name: 'Client Name',
          selector: 'Full_name',
          sortable: true,
          style: {
          },

        },
        {
          name: 'Client ID',
          selector: 'Client_id',
          sortable: true,
        },
        {
          name: 'Peer Support Specialist',
          selector: 'PeerSupportSpecialist',
          sortable: true,
        },
        {
          name: 'Date Of Birth',
          selector: 'Dob',
          sortable: true,
        },
        {
          name: 'Intake date',
          selector: 'Intake_date',
          sortable: true,
        },
        {
          name: 'Phone',
          selector: 'Primary_phone',
          sortable: true,


        },
        {
          name: 'Email',
          selector: 'Primary_email',
          sortable: true,
        },
        {
          name: 'Last Updated By',
          selector: 'Last_updated_by',
          sortable: true,
        },
        {
          name: 'Client Status',
          selector: 'Client_status',
          sortable: true,
        },
        // {
        //   name: 'Action',
        //   cell: (row) => (
        //     <div>
        //       <Link to="#"><i id={row.Client_id} className="fas fa-edit" aria-hidden="true" data-toggle="tooltip" title="Edit" onClick={this.handleButtonEdit('edit')}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //       {/*<Link to="#"><i id={row.Client_id} className="fas fa-arrow-right" aria-hidden="true" data-toggle="tooltip" title="View" onClick={this.handleButtonEdit('view')}></i></Link>*/}
        //     </div>
        //   ),
        //   ignoreRowClick: true,
        //   allowOverflow: true,
        //   button: true,
        // }
      ],
      suggestionArray : [],
      clientOnPage : 10
    };
  }

  handleChange = date => {
    this.setState({
      startDateintakedate: date,
    });
  };

  handleOrgChange = (event) => {
    sessionStorage.setItem('selected_organization', event.target.value);
    this.setState({ organisation: event.target.value });
  }

  saveOrgChange = (event) => {
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
      this.setState({ org_select_error: 'Select Organization' })
    } else {
      this.setState({ org_select_error: '', showOrganizationModal: false})
      this.getClientsList();
    }
  }

  async componentDidMount() {
    this.forSearch();
    let searchValues = sessionStorage.getItem('searchValues');
    if (searchValues != undefined && searchValues != null && searchValues != '') {
      let parsedSearchValues = JSON.parse(searchValues);
      await this.setState({ searchValue: parsedSearchValues });
      if (this.state.searchValue != null)
        this.getsearchresult();
    } else {
      sessionStorage.removeItem('client_status');
      if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
        this.showOrganizationsList();
      } else {
        this.getClientsList();
      }
    }
  }

  showOrganizationsList = () => {
    let orgs_list = JSON.parse(sessionStorage.getItem('permissions')).orgs_list;
    if (orgs_list === undefined || orgs_list === null) {
      orgs_list = [];
    }
    if (orgs_list.length == 1) {
      this.setState({ organisation: orgs_list[0].org_name });
      sessionStorage.setItem('selected_organization', orgs_list[0].org_name);
      this.getClientsList();
    } else {
      this.setState({ organizationsList: orgs_list, showOrganizationModal: true });
    }
  }

  CryptoJSAesDecrypt(passphrase,encrypted_json_string){
    const CryptoJS = require("crypto-js");
    var obj_json = JSON.parse(encrypted_json_string);
    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);   
    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  limitWithSearch = (param) => {
    var jsonData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      page: "1",
      filters : {
        'organization' : sessionStorage.getItem('selected_organization'),
      },
      limit : param ? param : 10
    };
    var formData = $("#clientsearchform").serializeArray();
    $.each(formData, function () {
      let name = [this.name];
      if (this.value != "") {
        jsonData.filters[name] = this.value;
      }
    });
    axios.post(customURLGen("clientList"), JSON.stringify(jsonData)).then(async res => {
      const returndata = res.data; // get the data array instead of object
      if (returndata.success && res.status == 200) {
        await this.setState({
          data: JSON.parse( this.CryptoJSAesDecrypt("tactionData" , returndata.data)),//returndata.data,
          suggestionArray : JSON.parse( this.CryptoJSAesDecrypt("tactionData" , returndata.data)),
          pending: false,
          search : true
        });
        this.setState({ pageInfo: returndata.pageInfo })
        // this.setState({ listNext : 1 });
      }else if ("error_code" in returndata) {
        console.log("Session Out");
        window.location.href = global.domain + global.traversArray['out'];
      }else {
        alert(returndata.msg)
      }
    })
    .catch(err => { // log request error and prevent access to undefined state
      this.setState({ loading: false, error: true });
      console.error(err);
    });
    console.log(jsonData);
  }

  getClientsList = (param) => {
    var jsonData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      page: "1",
    };
    jsonData.filters = {};
    if (sessionStorage.getItem('client_search_filter') === 'requested_status') {
      jsonData.filters['client_status'] = ['Activate', 'Inactivate'];
    }
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
      this.setState({ showOrganizationModal: true });
      return true;
    } else {
      jsonData.filters['organization'] = sessionStorage.getItem('selected_organization');
    }
    if(param != null || param != "" || param != undefined){
      jsonData.limit = param;
    }
    axios.post(customURLGen("clientList"), JSON.stringify(jsonData))
      .then(res => {
        const returndata = res.data; // get the data array instead of object
        if (returndata.success && res.status == 200) {
          this.setState({
            data: JSON.parse(this.CryptoJSAesDecrypt("tactionData" ,returndata.data)),//returndata.data,
            pending: false,
          });
          this.setState({
            pageInfo: returndata.pageInfo,
            pending: false,
          });
        }
        else if ("error_code" in returndata) {
          console.log("Session Out");
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          sessionStorage.setItem('error_msg', returndata.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
        //window.location.href = global.domain+global.traversArray['out'];
      }
    )
  }

  handleButtonEdit = action => (event) => {
    var rowData = "";
    this.state.data.map((row) => {
      if (row.Client_id === event.target.id)
        rowData = row;
    });

    sessionStorage.setItem('clientId', rowData.Client_id);
    encryptStorage.setItem('client_last_name', rowData.last_name);
    encryptStorage.setItem('ssn', rowData.ssn_no);
    encryptStorage.setItem('client_middle_initial', rowData.middle_name);
    encryptStorage.setItem('client_first_name', rowData.first_name);
    encryptStorage.setItem('email', rowData.Primary_email)
    encryptStorage.setItem('phone', rowData.Primary_phone)
    encryptStorage.setItem('intake_date', moment(rowData.Intake_date).format('MM/DD/YYYY'));
    encryptStorage.setItem('dob', moment(rowData.Dob).format('MM/DD/YYYY'))
    encryptStorage.setItem('month_1_followup_date', date_validate(moment(rowData.Intake_date).format('MM/DD/YYYY'), 90));
    encryptStorage.setItem('last_updated_by', rowData.Last_updated_by);
    encryptStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    encryptStorage.setItem('Address_lns', rowData.Address_lns);
    sessionStorage.setItem('viewMode', 'false');
    encryptStorage.setItem('peer_support_specialist', rowData.PeerSupportSpecialist)
    sessionStorage.setItem('client_status', rowData.Client_status)
    encryptStorage.setItem('gpra_no', rowData.gpra_no !== null && rowData.gpra_no !== undefined ? rowData.gpra_no : "");
    sessionStorage.removeItem('client_search_filter')
    if (action === "edit") {
      setTimeout(() => window.location.href = "/dashboard", 500);
    } else {
      setTimeout(() => window.location.href = "/dashboard", 500);
    }
  };

  handleButtonView = (event) => {
    var rowData = "";
    this.state.data.map((row) => {
      if (row.Client_id === event.target.id)
        rowData = row;
    });

    sessionStorage.setItem('clientId', rowData.Client_id);
    encryptStorage.setItem('client_last_name', rowData.last_name);
    encryptStorage.setItem('ssn', rowData.ssn_no);
    encryptStorage.setItem('client_middle_initial', rowData.middle_name);
    encryptStorage.setItem('client_first_name', rowData.first_name);
    encryptStorage.setItem('email', rowData.Primary_email)
    encryptStorage.setItem('phone', rowData.Primary_phone)
    encryptStorage.setItem('intake_date', moment(rowData.Intake_date).format('MM/DD/YYYY'));
    encryptStorage.setItem('dob', moment(rowData.Dob).format('MM/DD/YYYY'))
    encryptStorage.setItem('month_1_followup_date', date_validate(moment(rowData.Intake_date).format('MM/DD/YYYY'), 90));
    encryptStorage.setItem('last_updated_by', rowData.Last_updated_by);
    encryptStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    encryptStorage.setItem('Address_lns', rowData.Address_lns);
    sessionStorage.setItem('viewMode', 'false');
    encryptStorage.setItem('peer_support_specialist', rowData.PeerSupportSpecialist)
    sessionStorage.setItem('client_status', rowData.Client_status)
    sessionStorage.removeItem('client_search_filter')
    setTimeout(() => window.location.href = "/dashboard", 500);
  };



  handleRowClicked = (rowData) => {
    sessionStorage.setItem('clientId', rowData.Client_id);
    encryptStorage.setItem('client_last_name', rowData.last_name);
    encryptStorage.setItem('ssn', rowData.ssn_no);
    encryptStorage.setItem('gpra_no', rowData.gpra_no !== null && rowData.gpra_no !== undefined ? rowData.gpra_no : "");
    encryptStorage.setItem('client_middle_initial', rowData.middle_name);
    encryptStorage.setItem('client_first_name', rowData.first_name);
    encryptStorage.setItem('email', rowData.Primary_email)
    encryptStorage.setItem('phone', rowData.Primary_phone)
    encryptStorage.setItem('intake_date', moment(rowData.Intake_date).format('MM/DD/YYYY'));
    encryptStorage.setItem('dob', moment(rowData.Dob).format('MM/DD/YYYY'))
    encryptStorage.setItem('month_1_followup_date', date_validate(moment(rowData.Intake_date).format('MM/DD/YYYY'), 90));
    encryptStorage.setItem('last_updated_by', rowData.Last_updated_by);
    encryptStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    encryptStorage.setItem('Address_lns', rowData.Address_lns);
    sessionStorage.setItem('viewMode', 'false');
    encryptStorage.setItem('peer_support_specialist', rowData.PeerSupportSpecialist);
    sessionStorage.setItem('client_status', rowData.Client_status);
    sessionStorage.removeItem('client_search_filter');
    setTimeout(() => window.location.href = "/dashboard", 500);
  }

  clearclientid() {
    sessionStorage.setItem('clientId', '');
    sessionStorage.setItem('viewMode', '');
    encryptStorage.setItem('peer_support_specialist', '')
    encryptStorage.setItem('intake_date', '');
    encryptStorage.setItem('dob', '');
    encryptStorage.setItem('month_1_followup_date', '')
    encryptStorage.setItem('email', '');
    encryptStorage.setItem('phone', '');
    encryptStorage.setItem('last_updated_by', '');
    encryptStorage.setItem('gpra_no', '');
    sessionStorage.setItem('formname', '');
    sessionStorage.setItem('responceId', '');
    encryptStorage.setItem('client_first_name', '');
    encryptStorage.setItem('client_middle_initial', '');
    encryptStorage.setItem('client_last_name', '');
    encryptStorage.setItem('ssn', '');
    encryptStorage.setItem('last_updated_by', '');
    encryptStorage.setItem('Secondary_phone', '');
    sessionStorage.setItem('Leave_msg_pri', '');
    sessionStorage.setItem('Leave_msg_sec', '');
    sessionStorage.setItem('Type_lns', '');
    encryptStorage.setItem('Address_lns', '');
    sessionStorage.setItem('client_status', '')
    sessionStorage.removeItem('client_search_filter');
    setTimeout(() => window.location.href = "/intake-basicinfo", 500);
  }

  resetSearchParams = async (event) => {
    $('#zipcode_mailing').removeClass('is-invalid');
    this.setState({ listNext: 1, searchValue: null, clientOnPage : 10});
    sessionStorage.removeItem('searchValues');
    this.setState({  });
    await this.setState({ startDateintakedate: '' });
    await $("#clientsearchform :input").val('');
    this.getClientsList(10);
    setTimeout(() => this.refs.email_typeahead.getInstance().clear(), 10);
    setTimeout(() => this.refs.name_typeahead.getInstance().clear(), 10);
  }

  checkUpdatedSearchParam = () =>{
    let CheckAble = $("#clientsearchform").serializeArray().map((updatedValue) => { return updatedValue.value});
    for(let i=0; i < CheckAble.length; i++) {
      if(CheckAble[i].length   >= 1)
      return true;
    }
    return false;
  }

  async getsearchresult(event) {
    var jsonData = { 'apikey': sessionStorage.getItem('__TOKEN__') };
    if(event == undefined || event == '' || null) {
      //  Nothing be Happening..........!!
    }else if(typeof(event) == 'number'){
      if(event <= this.state.pageInfo.pages){
        jsonData.page = event;
      }
    }else{
      event.preventDefault();
    }
    this.setState({ pending: true });
    jsonData.filters = {}
    if (sessionStorage.getItem('client_search_filter') === 'requested_status') {
      jsonData.filters['client_status'] = ['Activate', 'Inactivate'];
    }
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
      return true;
    } else {
      jsonData.filters['organization'] = sessionStorage.getItem('selected_organization');
    }
    if (this.state.searchValue != null && this.state.searchValue != undefined && this.state.searchValue != '' && this.checkUpdatedSearchParam() == false) {
      var formData = this.state.searchValue;
      this.setState({ listNext: 1 });
    } else {
      var formData = $("#clientsearchform").serializeArray();
      this.setState({ searchValue: null });
    }
    sessionStorage.removeItem('searchValues');
    if (this.state.startDateintakedate != "") {
      jsonData.filters['dob'] = this.state.startDateintakedate;
    }
    jsonData.limit = this.state.clientOnPage;
    $.each(formData, function () {
      if (jsonData.filters[this.name]) {
        if (!jsonData.filters[this.name].push) {
          jsonData.filters[this.name] = [jsonData.filters[this.name]];
        }
        if (this.value !== '')
          jsonData.filters[this.name].push(this.value || '');
      }
      else {
        if (this.value !== '')
          jsonData.filters[this.name] = this.value || '';
      }
    });
    // if(formData[0].value == "" && formData[1].value == "" && formData[2].value == "" && formData[3].value == "" && formData[4].value == "" && formData[5].value == ""){
    //   alert("You can't search with out any Value.");
    //   jsonData.filters.name = "!@#$%^&**55664564654(&*^$";
    //   // this.withValidationSearch(jsonData);
    // }
    await axios.post(customURLGen("clientList"), JSON.stringify(jsonData))
    .then(async res => {
      const returndata = res.data; // get the data array instead of object
      if (returndata.success && res.status == 200) {
        await this.setState({
          data: JSON.parse( this.CryptoJSAesDecrypt("tactionData" , returndata.data)),//returndata.data,
          suggestionArray : JSON.parse( this.CryptoJSAesDecrypt("tactionData" , returndata.data)),
          pending: false,
          search : true
        });
        this.setState({ pageInfo: returndata.pageInfo })
        // this.setState({ listNext : 1 });
      }else if ("error_code" in returndata) {
        console.log("Session Out");
        window.location.href = global.domain + global.traversArray['out'];
      }else {
        alert(returndata.msg)
      }
    })
    .catch(err => { // log request error and prevent access to undefined state
      this.setState({ loading: false, error: true });
      console.error(err);
    });
    this.forSearch();
  }

  forSearch = () =>{
    var jsonData2 = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      "organization": sessionStorage.getItem('selected_organization')
    };
    axios.post(global.restApiURL+'customRest/getClientAutoSearch', JSON.stringify(jsonData2))
      .then(res => {
        // const returndata = res.data; // get the data array instead of object
        if (res.data.success && res.status == 200) {
          let myData = []
          // res.data.data.map((item) => {
          //   myData.push(item.client_name)
          // })
          JSON.parse(this.CryptoJSAesDecrypt("tactionData" , res.data.data)).map((item) => {
            myData.push(item.client_name)
          })
          this.setState({suggestionArray : myData});
        }
        else if ("error_code" in res.data) {
          console.log("Session Out");
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          sessionStorage.setItem('error_msg', res.data.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        // this.setState({ loading: false, error: true });
        console.error(err);
      }
    )
  }

  setDate(event, picker) {
    var startDate = this.convert(String(picker.startDate));
    var endDate = this.convert(String(picker.endDate));
    var dateRange = startDate + " - " + endDate;
    this.setState({
      dateRange: dateRange
    });
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  validateEmail = (event) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      this.setState({ email_error: '' });
    } else {
      this.setState({ email_error: "Please enter valid email address" });
    }
  }

  handleDateChangeRaw = (e) => {
    this.setState({ startDateintakedateOnBlur: e.target.value});
  }

  handleDateOnBlur = (date) => {
    let startDateintakedateOnBlur;
    let dateDiff = moment().diff(moment(date), 'years');
    const formatedDate = moment(date, "MM/DD/YYYY", true);
    if (!formatedDate.isValid() && date !== "") {
      if (this.state.startDateintakedate === "") {
        this.setState({ startDateintakedateOnBlur: "" })
        return
      }else {
        alert("Invalid Date");
        this.setState({ startDateintakedate: "" })
      }
    } else {
      if (this.state.startDateintakedate === "") {
        this.setState({ startDateintakedateOnBlur: "" })
        return
      } else if (dateDiff < 14) {
        startDateintakedateOnBlur = moment().subtract(14, 'years').format('MM/DD/YYYY');
      } else {
        startDateintakedateOnBlur = moment(date._d).format('MM/DD/YYYY');
      }
      this.setState({ startDateintakedate: startDateintakedateOnBlur === "" ? "" : moment.utc(startDateintakedateOnBlur).format('MM/DD/YYYY') });
    }
  }


  handlesortClientList = async (e) => {
    this.setState({ sortClientList: e.target.value, listNext: 1 });
    let payload = {
      apikey: sessionStorage.getItem("__TOKEN__"),
      page: 1,
      limit: this.state.clientOnPage,
      filters: {
        organization: sessionStorage.getItem("selected_organization"),
        user_id: sessionStorage.getItem("loggedinUserID"),
        sort_by: e.target.value,
        user_name: sessionStorage.getItem('loggedinusername'),
      }
    }
    let res = await axios.post(global.restApiURL + "customRest/getClientsfilterwithdropdwon", JSON.stringify(payload));
    if (res.data.success) {
      await this.setState({
        data: res.data.data,
        pending: false,
        pageInfo: res.data.pageInfo,
        search: true
      });
    }
  }

  handleClientOnPage = (e) => {
    this.setState({ clientOnPage: e.target.value, listNext: 1 });
    let payload = {
      apikey: sessionStorage.getItem("__TOKEN__"),
      page: 1,
      limit: e.target.value,
      filters: {
        organization: sessionStorage.getItem("selected_organization"),
        user_id: sessionStorage.getItem("loggedinUserID"),
        sort_by: this.state.sortClientList,
        user_name: sessionStorage.getItem('loggedinusername'),
      }
    }
    if(this.checkUpdatedSearchParam()){
      // this.getsearchresult();
      this.limitWithSearch(e.target.value);
    }
    else if(this.state.sortClientList != ""){
      axios.post(global.restApiURL + "customRest/getClientsfilterwithdropdwon", JSON.stringify(payload)).then((val) =>{
        if(val.data.success){
          this.setState({
            data : val.data.data,
            pending : false,
            pageInfo : val.data.pageInfo,
          });
        }
      }).catch((err) => {
        alert(err);
      });
    }
    else{
      this.getClientsList(e.target.value);
    }
  }

  generatePagination = () => {
    const paginationItems = [];
    paginationItems.push(
      <div className="card-body">
        <div className="sc-fzXfMB tnaXA">
          <nav className="sc-fzXfNc reOZF rdt_Pagination row">
            <span class="sc-fzXfNf sc-fzXfNg fsxVqc">{this.state.pageInfo.pageString} of {this.state.pageInfo.records}</span>
            <div className="sc-fzXfNe cAfIxA">
              <button onClick={() => { this.paginationDataTable(1) }} id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                  <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                  <path fill="none" d="M24 24H0V0h24v24z"></path>
                </svg>
              </button>
              <button onClick={() => { if (this.state.listNext <= 1) { } else { this.paginationDataTable((this.state.listNext - 1)) } }} id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              <button onClick={() => { if (this.state.listNext >= this.state.pageInfo.pages) { } else { this.paginationDataTable((this.state.listNext + 1)) } }} id="pagination-next-page" aria-label="Next Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="qnf8l" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              <button onClick={() => { this.paginationDataTable(this.state.pageInfo.pages) }} id="pagination-last-page" aria-label="Last Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="bxu9i" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                  <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                </svg>
              </button>
            </div>

          </nav>
        </div>
      </div>
    );
    return paginationItems;
  };

  paginationDataTable = async (pageNum) => {
    this.setState({ listNext: pageNum })
    if (this.state.sortClientList != "") {
      let payload = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        page: pageNum,
        limit : this.state.clientOnPage,
        filters: {
          organization: sessionStorage.getItem("selected_organization"),
          user_id: sessionStorage.getItem("loggedinUserID"),
          sort_by: this.state.sortClientList,
          user_name:sessionStorage.getItem('loggedinusername'),
        }
      }
      let res = await axios.post(global.restApiURL + "customRest/getClientsfilterwithdropdwon", JSON.stringify(payload));
      if (res.data.success) {
        this.setState({ data: res.data.data })
        this.setState({ pageInfo: res.data.pageInfo })
      }
    } else {
      var jsonData = {
        'apikey': sessionStorage.getItem('__TOKEN__'),
        page: pageNum
      };
      jsonData.filters = {};
      if (sessionStorage.getItem('client_search_filter') === 'requested_status') {
        jsonData.filters['client_status'] = ['Activate', 'Inactivate'];
      }
      if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
        this.setState({ showOrganizationModal: true });
        return true;
      } else {
        jsonData.filters['organization'] = sessionStorage.getItem('selected_organization');
      }
      jsonData.limit = this.state.clientOnPage;
      // var datasend = JSON.stringify(jsonData);
      if(this.state.search == true){
        this.getsearchresult(pageNum);
      }else{
        axios.post(customURLGen("clientList"), JSON.stringify(jsonData))
        .then(res => {
          const returndata = res.data; // get the data array instead of object
          if (returndata.success && res.status == 200) {
            this.setState({
              data: JSON.parse( this.CryptoJSAesDecrypt("tactionData", returndata.data) ) ,//returndata.data,
              pending: false,
            });
            this.setState({ pageInfo: returndata.pageInfo })
          }
          else if ("error_code" in returndata) {
            console.log("Session Out");
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            sessionStorage.setItem('error_msg', returndata.msg);
          }
        }
        )
      }
    }
  }

  render() {

    const pageAccessedByReload = (
      (window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
          .getEntriesByType('navigation')
          .map((nav) => nav.type)
          .includes('reload')
    );
    
    if(pageAccessedByReload){
      sessionStorage.removeItem("searchValues")
    }

    let { data } = this.state;
    const inputProps = { name: 'name' };
    const inputEmailProps = { name: 'primary_email', id: 'primary_email' }
    let email_options = [];
    let client_names = [];
    this.state.data.map((client) => {
      if (client.Full_name !== '') {
        client_names.push({ Full_name: client.Full_name });
      }
      if (client.Primary_email !== '') {
        email_options.push({ Primary_email: client.Primary_email });
      }
    })
    const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection);
    let orgs_list = this.state.organizationsList.map(org => (
      <option value={org.org_name} selected={sessionStorage.getItem('selected_organization') === org.org_name}>{org.org_name}</option>
    ));
    $(document).ready(function () {
      var NumRegex = /^[0-9]+$|^$|^\s$/;
      var EmailRegex = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      $.validator.addMethod('NumRegex', function (value, element, parameter) {
        return value.match(NumRegex);
      }, 'Please enter a valid ZIP code.');

      $.validator.addMethod('EmailRegex', function (value, element, parameter) {
        return value.match(EmailRegex);
      }, 'Please enter a valid email address.');

      $('#clientsearchform').validate({
        rules: {
          zipcode_mailing: {
            NumRegex: true
          },
          // email validation remove
          // primary_email: {
          //   EmailRegex: true
          // },
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
        onkeyup: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
        onblur: function (element) {
          $('#primary_email').valid();
          $('#zipcode_mailing').valid();
        },
        onfocus: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });

    const years = moment().subtract(150, 'years');
    const disablePastDt = current => {
      return current.isAfter(years);
    };

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <Topnav organisation={this.state.organisation} />
          <div className="content-wrapper">
            <section className="content-header">
            </section>
            <section className="content">
              <form method='post' id="clientsearchform" >
                <div className="card">
                  <div className="card-header" >
                    <h3 className="card-title">Search</h3>
                  </div>
                  <div id="searchdiv" className="card-body row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Client Name</label>
                        <Typeahead
                          ref='name_typeahead'
                          inputProps={inputProps}
                          labelKey="Full_name"
                          options={this.state.suggestionArray }
                          placeholder="Client Name"
                          className="search"
                          id="name"
                          clearButton={true}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              this.getsearchresult(); 
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Client ID</label>
                        <input type="text" className="form-control" id="client_id" name="client_id" placeholder="Client ID" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Date of Birth (MM/DD/YYYY)</label>
                        <div className="input-group date">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                          <DateTime
                            onBlur={(date) => this.handleDateOnBlur(date)}
                            onChange={(date) => this.handleChange(date)}
                            inputProps={{ maxLength:10,value: typeof this.state.startDateintakedate === 'string' ? this.state.startDateintakedate : moment(this.state.startDateintakedate).format('MM/DD/YYYY'), placeholder: 'Date of birth' }} //UTC has been removed because the selected date is blurry and appears 1 day earlier.
                            // defaultValue={moment.utc(this.state.startDateintakedate).format('MM/DD/YYYY')}
                            defaultValue={moment().subtract(14, 'years').format('MM/DD/YYYY')}
                            dateFormat="MM/DD/YYYY"
                            timeFormat={false}
                            input={true}
                            id="dob" name="dob"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Intake Date</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                          <DateRangePicker onApply={this.setDate} locale={this.state.locale} className="">
                            <input className="form-control pull-right" id="intake_date" name="intake_date" placeholder="Intake Date" readOnly type="text" defaultValue={this.state.dateRange} style={{ backgroundColor: "transparent" }} />
                          </DateRangePicker>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Phone</label>
                        <input type="text" className="form-control" id="primary_phone" maxLength={15} name="primary_phone" placeholder="Phone" data-inputmask="'mask': '(999) 999-9999'" data-mask />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Email</label>
                        <Typeahead
                          ref='email_typeahead'
                          // id="basic-typeahead-example"
                          inputProps={inputEmailProps}
                          labelKey="Primary_email"
                          options={email_options}
                          placeholder="Email"
                          className="search"
                          id="primary_email"
                          clearButton={true}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              this.getsearchresult(); 
                            }
                          }}
                        />
                        {this.state.email_error !== "" ? (<span className="text-danger">{this.state.email_error}</span>) : ""}
                      </div>
                    </div>
                    {/*<div className="col-md-3">
                          <div className="form-group">
                            <label>Form Filled</label>
                            <select className="custom-select" >
                              <option>Select</option>
                              <option>Intake</option>
                              <option>Client Information</option>
                              <option>Consent</option>
                              <option>Authorization</option>
                              <option>Intake Assesment</option>
                              <option>Client Health Screenings</option>
                              <option>Complete GAD Screening Tool</option>
                              <option>Complete PHQ-9</option>
                              <option>Complete Q-LES-Q-SF</option>
                              <option>CSSR</option>
                              <option>GPRA Home Screen</option>
                              <option>Recovery Goals</option>
                              <option>Client Contact Info</option>
                              <option>Routing Form</option>
                              
                      <option>Form3</option>
                                <option>Form4</option>
                              </select>
                            </div>
                      </div>*/}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>ZIP Code</label>
                        <input type="text" className="form-control" id="zipcode_mailing" name="zipcode_mailing" placeholder="ZIP Code" maxLength={5} minLength={5} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group ">
                        <label>&nbsp;</label><br />
                        <button type="button" onClick={this.resetSearchParams} className="btn btn-default float-sm-right clientSearchButton" > Reset</button>
                        <button type="submit" onClick={this.getsearchresult} className="btn btn-primary float-sm-right btnmargin clientSearchButton" > Search</button>

                      </div>
                    </div>

                  </div>

                </div>
              </form>
            </section>
            <section className="content">
              <div className="card">
                <div className='row card-header'>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-3'>
                        <h4 className="text-green">Client List</h4>
                      </div>
                      <div className='col-3'>
                        <select className="form-control" name="sortClientList" value={this.state.sortClientList} onChange={this.handlesortClientList} >
                          <option disabled value="">Filter By</option>
                          <option value="all">All Clients</option>
                          <option value="last_updated_by_me">Last updated by me</option>
                          <option value="my_clients">My Clients</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <p className='float-right'>
                      {can_add('ClientInformation') ? <Link to="intake-basicinfo" className="btn btn-success " onClick={this.clearclientid}><i className="fas fa-plus"></i> Add Client</Link> : ''}
                    </p>
                  </div>
                </div>

                <div className="card-body">
                  <DataTable
                    columns={this.state.columns}
                    data={data}
                    onRowClicked={this.handleRowClicked}
                    customStyles={global.customTableStyles}
                    progressPending={this.state.pending}
                    striped={true}
                    // pagination
                    paginationComponentOptions={global.paginationComponentOptions}
                    highlightOnHover
                    pointerOnHover
                    noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                    onSort={handleSort}
                    sortFunction={customSort}
                  />
                  <br />
                  <div className='row'>
                    <div className='col-6'>
                      <ul class="pagination paginationClient pagination-lg">
                        {this.generatePagination()}
                      </ul>
                    </div>
                    <div className='col-6'>
                      <ul class="float-right mt-4">
                      <span class="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">Clients per Page :- </span>
                        <select style={{color:'rgb(0, 123, 255)'}} onChange={(e) => this.handleClientOnPage(e)} name="clientOnPage" id='clientOnPage' value={this.state.clientOnPage}>
                          <option selected={true} value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                          <option value={50}>50</option>
                          <option value={60}>60</option>
                          <option value={70}>70</option>
                          <option value={80}>80</option>
                          <option value={90}>90</option>
                          <option value={100}>100</option>
                        </select>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Modal
            showOverlay={true}
            show={this.state.showOrganizationModal}
            disableOverlayClick={true}
            showCloseButton={false}
            size="sm"
            className="modal-lg modal-dialog"
            style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
          >
            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
              <Modal.Title>
                <div className="row" >
                  <div className="col-sm-12">
                    <p className="lead float-left"><b className="text-green">Select Organization</b></p>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset>
                {this.state.organizationsList.length > 1 ? (
                  <div>
                    {this.state.org_select_error !== '' ? (<div className="text-danger">{this.state.org_select_error}</div>) : ''}
                    <label id="select_organiztion">Select Organization</label>
                    <select className="form-control" id="Organization" onChange={this.handleOrgChange}>
                      <option value="">Select</option>
                      {orgs_list}
                    </select>
                  </div>) : (
                  <p className="text-danger">No Organization is assigned to you. Contact Administrator.</p>)}

              </fieldset>


            </Modal.Body>
            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="justify-content-between">
                    <button className="btn btn-success float-right btnmargin" type="button" onClick={this.saveOrgChange}>Select</button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Footer sidebar={false} />



        </div>
      </div>
    );
  }
}
export default Clients





